<template>
  <div class="container-fluid">
    <div class="row">
      <AlertError :error="error" />
      <div class="my-4 ">
        <router-link class="sub-links me-4" :to="'/management/articles/list'">
          <i class="bi bi-list-ol"></i>
          Lista Articoli
        </router-link>
        <a v-if="!createMode" href="/management/articles" class="sub-links">
          <i class="bi bi-plus-lg"></i>
          Crea
        </a>
      </div>
      <div class="col-md-5 mb-3">
        <label for="title">Titolo</label>
        <input id="title" type="text" class="form-control" v-model="article.title" />
      </div>
      <div class="col-md-5 mb-3">
        <label for="image">URL Immagine</label>
        <input id="image" type="text" class="form-control" v-model="article.image_url" />
      </div>
      <div class="col-md-12 mb-3">
        <label>Contenuto</label>
        <editor
            initialValue="<p>Initial editor content</p>"
            apiKey="3cn8zqisxqb4wn2x6dwdcpxx360qqbbr2kka5c5cph6e706t"
            v-model="article.content"
            :init="{
              height: 500,
              menubar: false,
              plugins: [
                'advlist autolink lists link image charmap',
                'searchreplace visualblocks code fullscreen',
                'print preview anchor insertdatetime media',
                'paste code help wordcount table'
              ],
              toolbar:
                'undo redo | formatselect | bold italic | \
                alignleft aligncenter alignright | \
                bullist numlist outdent indent | help'
            }"
        >
        </editor>
      </div>
      <div class="col-12">
        <button class="btn bg-blue" @click="handle">
          {{ createMode ? 'Salva' : 'Aggiorna' }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'
import {createArticle, showArticle, updateArticle} from '@/api/news.api';
import AlertError from "@/components/alerts/AlertError";
import { sweetAlertSuccess } from "@/utilities/sweetalert";

export default {
  name: "HandleNews",
  components: {AlertError, Editor },
  data() {
    return {
      article: {
        title: '',
        category: 'GENERAL',
        content: '',
        type: 'NEWS',
        image_url: '',
      },
      error: '',
      newsId: '',
      createMode: true,
    }
  },
  methods: {
    validate() {
      this.error = '';

      if (!this.article.title) {
        this.error = 'Titolo obbligatorio';

        return true;
      }

      if (!this.article.content) {
        this.error = 'Contenuto obbligatorio';

        return true;
      }

      return false;
    },
    handle() {
      if (this.validate()) return;

      if (this.createMode) {
        this.save();
      } else {
        this.update();
      }
    },
    async save() {
      const response = await createArticle(this.article);

      if (response.data) {
        this.article = response.data;
        await sweetAlertSuccess('success', 'Articolo creato!');

        return;
      }

      await sweetAlertSuccess('error', 'Si è verificato un errore.');
    },
    async show(id) {
      const response = await showArticle(+id);
      this.article = response.data;
    },
    async update() {
      const response = await updateArticle(+this.newsId, this.article);

      if (response.data) {
        this.article = response.data;
        await sweetAlertSuccess('success', 'Articolo aggiornato!');

        return;
      }

      await sweetAlertSuccess('error', 'Si è verificato un errore.');
    }
  },
  mounted() {
    if (this.$route.params.id) {
      this.newsId = this.$route.params.id;
      this.createMode = false;
      this.show(this.newsId);
    }
  }
}
</script>

<style scoped>
  button,
  .form-select,
  .form-control {
    border-radius: 22px;
  }

  .sub-links {
    background-color: #173759;
    color: #f1f1f1;
    padding: 0.8rem 0.7rem;
    font-size: 1.2rem;
    border-radius: 1rem;
    text-decoration: none;
    font-weight: 500;
  }

  .sub-links:hover {
    color: #173759;
    background-color: #fff;
  }
</style>